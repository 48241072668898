import appAxios from 'src/app-axios';
import Constants from 'src/constants';

export const members = {
    namespaced: true,
    state: {
        membersList: [],
    },
    getters: {
        getMembersList (state) {
            return state.membersList;
        },
    },
    mutations: {
        setMembersList (state, data) {
            state.membersList = data;
        },
    },
    actions: {
        async fetchMembersList ({ dispatch, commit }) {
            const url = 'api/organizations/users';
            try {
                const details = await appAxios.get(url);
                commit('setMembersList', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting the members list.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
    },
};
