import appAxios from 'src/app-axios';
import Constants from 'src/constants';

export const appusers = {
    namespaced: true,
    state: {
        createdAppUsersStructure: [],
        savedAppUsersStructure: {},
        appUsers: {},
        appUserDocuments: [],
        appUserAllActivities: {},
        appUserRecentActivities: [],
        appUserInvoices: [],
        appUserDetails: {},
        appUsersPending: {},
    },
    getters: {
        getCreatedAppUsersStructure (state) {
            return state.createdAppUsersStructure;
        },
        getSavedAppUsersStructure (state) {
            return state.savedAppUsersStructure;
        },
        getAppUsers (state) {
            return state.appUsers;
        },
        getAppUserDocuments (state) {
            return state.appUserDocuments;
        },
        getAppUserAllActivities (state) {
            return state.appUserAllActivities;
        },
        getAppUserRecentActivities (state) {
            return state.appUserRecentActivities;
        },
        getAppUserInvoices (state) {
            return state.appUserInvoices;
        },
        getAppUserDetails (state) {
            return state.appUserDetails;
        },
        getLeastActiveAppUsers (state) {
            return state.leastActiveAppUsers;
        },
        getPendingAppUsers (state) {
            return state.appUsersPending;
        },
    },
    mutations: {
        setCreatedAppUsersStructure (state, data) {
            state.createdAppUsersStructure = data;
        },
        setSavedAppUsersStructure (state, data) {
            state.savedAppUsersStructure = data;
        },
        setAppUsers (state, details) {
            state.appUsers = details;
        },
        setAppUserDocuments (state, details) {
            state.appUserDocuments = details;
        },
        setAppUserAllActivities (state, details) {
            state.appUserAllActivities = details;
        },
        setAppUserRecentActivities (state, details) {
            state.appUserRecentActivities = details;
        },
        setAppUserInvoices (state, details) {
            state.appUserInvoices = details;
        },
        setAppUserDetails (state, details) {
            state.appUserDetails = details;
        },
        setPendingAppUsers (state, details) {
            state.appUsersPending = details;
        },
        clearEmergencyContact (state) {
            state.appUserDetails = {};
        },
    },
    actions: {
        async createAppUser ({ dispatch }, payload) {
            const url = 'api/app-users';
            try {
                await appAxios.post(url, payload);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: exception.response.data.message,
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        createAppUsersStructure (context, payload) {
            context.commit('setCreatedAppUsersStructure', payload);
        },
        async fetchSavedAppUsersStructure ({ dispatch, commit }) {
            const url = 'api/app-users/group';
            try {
                const details = await appAxios.get(url);
                commit('setSavedAppUsersStructure', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting your grouped app users.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async groupAppUsers  ({ dispatch, commit }, payload) {
            const url = 'api/app-users/group';
            try {
                await appAxios.post(url, payload);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in creating the app users group.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchAppUsers ({ dispatch, commit }, payload) {
            const url = 'api/app-users';
            try {
                const details = await appAxios.get(url, payload.params);
                commit('setAppUsers', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting app users list.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async fetchAppUserDocuments ({ dispatch, commit }, payload) {
            const url = `api/app-users/${payload.appUserId}/documents`;
            try {
                const details = await appAxios.get(url);
                commit('setAppUserDocuments', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting app user documents.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async fetchAppUserAllActivities ({ dispatch, commit }, payload) {
            const url = `api/app-users/${payload.appUserId}/activities`;
            try {
                const details = await appAxios.get(url, payload.params);
                commit('setAppUserAllActivities', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting app user all activities.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async dowloadDocment ({ dispatch }, payload) {
            const url = `api/documents/${payload.documentId}/download`;
            appAxios({
                url: url,
                method: 'GET',
                responseType: 'blob',
            }).then(response => {
                const blob = new Blob([response.data]);
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = payload.fileName;
                document.body.appendChild(link);
                link.click();
                URL.revokeObjectURL(url);
            }).catch(exception => {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in downloading document file.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            });
        },
        async fetchAppUserRecentActivities ({ dispatch, commit }, { params, appUserId }) {
            const url = `api/app-users/${appUserId}/activities`;
            try {
                const details = await appAxios.get(url, { params: params });
                commit('setAppUserRecentActivities', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting app user recent activities.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchAppUserInvoices ({ dispatch, commit }, payload) {
            const url = `api/app-users/${payload.appUserId}/invoices`;
            try {
                const details = await appAxios.get(url);
                commit('setAppUserInvoices', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting app user invoices.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchAppUserDetails ({ dispatch, commit }, payload) {
            const url = `api/app-users/${payload.appUserId}`;
            try {
                const details = await appAxios.get(url);
                commit('setAppUserDetails', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: exception.response.data.message,
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async updateAppUserDetails ({ dispatch }, payload) {
            const url = `api/app-users/${payload.appUserId}`;
            try {
                await appAxios.put(url, payload.updateDetails);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in updating the app user details.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async deleteAppUser ({ dispatch }, payload) {
            const url = `api/app-users/${payload.appUserId}`;
            try {
                await appAxios.delete(url);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in deleting this app user.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async setAppUsersWorkHours  ({ dispatch }, payload) {
            const url = 'api/app-users/work-hours';
            try {
                await appAxios.put(url, payload);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in setting the app users work hours.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchPendingAppUsers ({ dispatch, commit }, payload) {
            const url = 'api/app-users/pending';
            try {
                const details = await appAxios.get(url, payload.params);
                commit('setPendingAppUsers', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting app users list.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        clearEmergencyContactData ({ commit }) {
            commit('clearEmergencyContact');
        },
    },
};
